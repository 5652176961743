<template>
  <header>
    <span class="back-button" @click="back"><span class="glyphicon glyphicon-menu-left"></span></span>
    <h1 class="title-logo">
      <a href="javascript:" @click="$router.push('/')"><img :src="`${root}assets/img/logo-terraindex.svg`" /></a>
    </h1>
    <div class="title-text">
      <div v-if="titleProjects" class="title-projects">
        {{ $t('pages.' + $route.name) }}
      </div>
      <div v-else>
        <div v-if="isPrev">
          <a href="javascript:" @click="gotoParent">{{ parentTitle }}</a>
        </div>
        <div v-else>
          <a href="javascript:" @click="gotoParent">{{ parentTitle }}{{ (prevTitle && ': ' + prevTitle) || '' }}</a>
        </div>
        <div v-if="isOnlyTitle" class="current">
          {{ $t('pages.' + $route.name) }}
        </div>
        <div v-else class="current">{{ $t(subTitleTranslationKey) }}: {{ activeTitle }}</div>
      </div>
    </div>
    <div class="dropdown">
      <div id="indexMenu" class="dropdown-toggle" data-toggle="dropdown">
        <span class="glyphicon glyphicon-menu-hamburger"></span>
      </div>
      <ul class="dropdown-menu" role="menu" aria-labelledby="indexMenu">
        <li v-if="isDevLicense">
          <a
            @click="
              () => {
                $router.push({ name: 'codeconventions' })
              }
            "
            >Front-end Code conventions</a
          >
        </li>
        <li v-for="(menu, key) in menus" :key="key" @click="goto(menu)">
          <a v-if="menu.enabled" href="javascript:" :class="{ disabled: !menu.enabled }">{{ $t('Menu.' + menu.name) }}</a>
        </li>
      </ul>
    </div>
    <span v-if="username" class="username">{{ $router.app.username }}</span>

    <div v-title="$t('tooltips.news')" class="newsmenu" :class="{ 'newsmenu-background-active': newsPanelVisible }" @click="setNewsPanelVisible(!newsPanelVisible)">
      <font-awesome-icon icon="fa-solid fa-gift" class="newsmenu-icon" />
      <div v-show="unreadNewsItems" class="newsmenu-unread"></div>
    </div>
    <div v-if="tileViewRoute || tableViewRoute" class="displayMode">
      <ul>
        <li v-if="tileViewRoute" :to="tileViewRoute" v-bind:title="$t('label.SwitchToTileMode')" @click="navigateAndSaveView('tile')"><span class="glyphicons glyphicons-show-thumbnails"></span></li>
        <li v-else class="active">
          <span class="glyphicons glyphicons-show-thumbnails"></span>
        </li>
        <li v-if="tableViewRoute" :to="tableViewRoute" v-bind:title="$t('label.SwitchToTableMode')" @click="navigateAndSaveView('table')"><span class="glyphicons glyphicons-table"></span></li>
        <li v-else class="active">
          <span class="glyphicons glyphicons-table"></span>
        </li>
        <li v-if="shouldShowDashboardView" :to="dashboardViewRoute" v-bind:title="$t('label.SwitchToDashboardMode')" @click="navigateAndSaveView('dashboard')">
          <span class="glyphicons glyphicons-list-alt"></span>
        </li>
      </ul>
    </div>
    <div class="dropdown">
      <div id="languageSelect" class="dropdown-toggle" data-toggle="dropdown">
        {{ currentLanguage }}
      </div>
      <ul class="dropdown-menu" role="menu" aria-labelledby="languageSelect">
        <li v-for="language in supportedLanguages" v-bind:key="language">
          <a @click="changeLanguage(language)">{{ language.toUpperCase() }}</a>
        </li>
      </ul>
    </div>
    <NewsPanel :newsPanelVisible="newsPanelVisible" @update:newsPanelVisible="setNewsPanelVisible" @update:unreadNewsItems="setUnreadNewsItems" />
  </header>
</template>

<script>
'use strict'

import _ from 'lodash'
import config from '@/configurations/config.js'
import authenticationProviders from '@/utils/authenticationProvider'
import cookie from 'vue-cookie'
import NewsPanel from '@/components/news/newsPanel'
import moment from 'moment'

import { UserManagementService } from '@/services/SSO/userManagementService'
const userManagementService = new UserManagementService()

let authenticationProvider = authenticationProviders[config.platform].default

export default {
  name: 'titleBar',
  components: {
    NewsPanel
  },
  props: {
    username: {
      type: Boolean,
      default: true
    },
    subTitleKey: {
      type: String,
      required: false
    },
    tileViewRoute: Object,
    tableViewRoute: Object,
    dashboardViewRoute: Object
  },
  data() {
    this.saveViewFromPreviousNavigation()
    return {
      root: config.root,
      menus: [],
      parentURL: '/',
      parentTitle: '',
      currentTitle: '',
      isPrev: false,
      isOnlyTitle: false,
      logoUrl: '../assets/logo.png',
      activeTitle: '',
      newsPanelVisible: false,
      unreadNewsItems: false
    }
  },
  mounted: function () {
    this.loadAndNormalizeLanguage()
  },
  computed: {
    shouldShowDashboardView() {
      return this.dashboardViewRoute && this.isDevLicense && (window.location.href.includes('test.terraindex.com') || window.location.href.includes('localhost'))
    },
    titleProjects: function () {
      return this.$route.name === 'projects'
    },
    prevTitle: {
      get() {
        return this.$store.state.prevTitle
      }
    },
    subTitleTranslationKey() {
      return this.subTitleKey ? this.subTitleKey : 'pages.' + this.$route.name
    },
    isDevLicense() {
      return ['7'].includes(cookie.get('licensenumber'))
    },
    currentLanguage() {
      return (cookie.get('lang') || 'NL').toUpperCase()
    },
    supportedLanguages() {
      return Object.keys(config.configLocales.messages)
    }
  },
  watch: {
    $route(to, from) {
      this.titleText()
    },
    '$store.state.activeTitle': {
      immediate: true,
      handler(newValue) {
        this.activeTitle = newValue
      }
    }
  },
  created() {
    this.getAuthorizations()
    this.titleText()
  },
  methods: {
    loadAndNormalizeLanguage() {
      let defaultLanguage = 'en' // default to English if no supported language code can be read

      let lang = cookie.get('lang')
      if (!lang || !this.supportedLanguages.includes(lang)) {
        let browserLocale = (cookie.get('language') || defaultLanguage).toLowerCase()
        let language = browserLocale.split('-')[0]
        if (this.supportedLanguages.includes(language)) {
          this.changeLanguage(language)
        } else {
          this.changeLanguage(defaultLanguage)
        }
      }
    },
    changeLanguage(languageCode) {
      cookie.set('lang', languageCode.toLowerCase())
      cookie.set('language', languageCode.toLowerCase())
      window.location.reload()
    },
    saveViewFromPreviousNavigation() {
      const view = this.$route.params.updateViewSetting
      if (view) {
        this.$store.dispatch('updateSetting', {
          settingName: 'defaultView',
          settings: view
        })
      }
    },
    navigateAndSaveView(currentView) {
      const route = {
        params: {
          projectId: this.$route.params.projectId.toString(),
          updateViewSetting: currentView
        }
      }

      if (currentView === 'table') {
        route.name = 'overview'
        route.params.overviewId = '108'
      } else if (currentView === 'dashboard') {
        route.name = 'dashboard'
        route.params.dashboardId = '1'
      } else {
        route.name = 'project'
      }

      this.$router.push(route).catch(() => {})
    },
    getAuthorizations() {
      userManagementService.getAuthorizations().then((resp) => {
        this.menus = [
          {
            name: 'Mijnaccount',
            func: this.goProfile
          },
          {
            name: 'Useradministration',
            func: this.goUser,
            keys: 'MT_SETTINGS'
          },
          {
            name: 'FieldTemplates',
            func: this.goFieldTemplates,
            keys: 'MT_FIELD'
          },
          {
            name: 'Codelist',
            func: this.goCodelist,
            keys: 'MT_FIELD'
          },
          {
            name: 'LicenseManagement',
            func: this.goLicenseManagement,
            keys: 'MT_LICENSE'
          },
          {
            name: 'TerraPedia',
            func: this.openWiki
          },
          {
            name: 'YouTube',
            func: this.openYouTube
          },
          {
            name: 'Uitloggen',
            func: this.logout
          }
        ].map((menu) => {
          menu.keys = menu.keys || []
          menu.keys = typeof menu.keys === 'string' ? [menu.keys] : menu.keys
          if (menu.keys.length) {
            menu.keys.push('IS_ADMIN')
            menu.enabled = _.some(menu.keys, (item) => _.includes(resp, item))
          } else {
            menu.enabled = true
          }
          delete menu.keys
          localStorage.setItem('UserRights', resp)
          return menu
        })
      })
    },
    goto(menu) {
      if (!menu.enabled) {
        return
      }

      menu.func && menu.func()
    },
    gotoParent(event) {
      this.$router.push(this.parentURL)
    },
    goProfile(event) {
      open(config.menus.profile)
    },
    goUser(event) {
      open(config.menus.user)
    },
    goFieldTemplates(event) {
      open(config.menus.fieldtemplate)
    },
    goCodelist(event) {
      open(config.menus.codelists)
    },
    goLicenseManagement(event) {
      open(config.menus.LicenseManagement)
    },
    goCitrixLogin(event) {
      open(config.menus.CitrixLogin)
      // this.$router.go("/citrixLogin");
    },
    openWiki(event) {
      const currentLang = cookie.get('language')
      switch (currentLang) {
        case 'nl':
          window.open('https://wiki.terraindex.com/bin/view/Main/?language=nl_NL', '_blank').focus()
          break
        case 'fr':
          window.open('https://wiki.terraindex.com/bin/view/Main/?language=fr_FR', '_blank').focus()
          break
        default:
          window.open('https://wiki.terraindex.com/bin/view/Main/?language=en', '_blank').focus()
          break
      }
    },
    openRoadmap(event) {
      window.open('https://portal.productboard.com/ahf3yhsjmvwpzumnre37h5ub/tabs/1-under-consideration', '_blank').focus()
    },
    openYouTube(event) {
      const currentLang = cookie.get('language')
      switch (currentLang) {
        case 'nl':
          window.open('https://www.youtube.com/playlist?list=PLqlV8fRBE38Ld_YOdzZMTezQSL09XcUzX', '_blank').focus()
          break
        case 'fr':
          window.open('https://www.youtube.com/playlist?list=PLvqR69cCdP6_TSTo3XnMtpCPXinbKPQeC', '_blank').focus()
          break
        default:
          window.open('https://www.youtube.com/playlist?list=PLqDcMobUO9TGvDeaBAj6NuHsWyow0lh4g', '_blank').focus()
          break
      }
    },
    async logout() {
      await this.$store.dispatch('clearLocalCache')
      authenticationProvider.signOut()
    },
    back() {
      if (this.$route.title === 'pages.Projects') {
        return
      }
      history.back()
    },
    titleText() {
      let val = this.$route
      switch (val.name) {
        case 'suggestAnalysisSamples':
        case 'projects': // no breadcrumbs
          break
        case 'project':
          this.navProject(val)
          break
        case 'measurementPoints':
          this.navMeasurementPoints(val)
          break
        case 'measurementPoint':
        case 'measurementPointMap':
        case 'concernedContact':
          this.navMeasurementPoint(val)
          break
        case 'layer':
        case 'soilSample':
        case 'gaugingTubes':
        case 'finishings':
        case 'watersample':
        case 'watersampleSurface':
        case 'bottle':
        case 'bottleSurface':
        case 'airSample':
        case 'airSampleSurface':
        case 'bottleAir':
        case 'bottleSurfaceAir':
          this.navMeasurementPointDetail(val)
          break
        case 'citrixLogin':
          this.navCitrixLogin(val)
          break
        case 'map':
          this.navMap(val)
          break
        case 'overview':
          this.navOverview(val)
          break
        case 'dashboard':
          this.navDashboard(val)
          break
        case 'subLocation':
          this.navSubLocation(val)
          break
        case 'summaryAndConclusion':
          this.navSummaryAndConclusions(val)
          break
        case 'browserWarning':
        case null:
          this.navNotLoaded()
          break
        default:
          console.error('Header.vue: Could not get titleText for route with name: [' + val.name + ']')
          break
      }
    },
    navNotLoaded() {
      this.parentTitle = '-'
      this.parentURL = ''
      this.isPrev = false
      this.isOnlyTitle = true
    },
    navProject(val) {
      this.parentTitle = this.$t('label.projects')
      this.parentURL = '/'
      this.isPrev = true
      this.isOnlyTitle = false
    },
    navMeasurementPoints(val) {
      this.parentURL = '/project/' + val.params.projectId
      this.parentTitle = this.$t('label.backToProject')
      this.isPrev = false
      this.isOnlyTitle = true
    },
    navMeasurementPoint(val) {
      this.parentURL = '/project/' + val.params.projectId
      this.parentTitle = this.$t('label.backToProject')
      this.isPrev = false
      this.isOnlyTitle = false
    },
    navSummaryAndConclusions(val) {
      this.parentURL = '/project/' + val.params.projectId
      this.parentTitle = this.$t('label.backToProject')
      this.isPrev = false
      this.isOnlyTitle = false
    },
    navSubLocation(val) {
      this.parentURL = '/project/' + val.params.projectId
      this.parentTitle = this.$t('label.backToProject')
      this.isPrev = false
      this.isOnlyTitle = false
    },
    navMeasurementPointDetail(val) {
      this.parentURL = '/project/' + val.params.projectId + '/measurementPoint/' + this.$route.params.measurementPointId
      this.parentTitle = this.$t('label.backToMeasurementPoint')
      this.isPrev = false
      this.isOnlyTitle = false
    },
    navCitrixLogin(val) {
      this.parentTitle = this.$t('label.projects')
      this.parentURL = '/'
      this.isPrev = true
      this.isOnlyTitle = true
    },
    navMap(val) {
      this.parentTitle = this.$t('label.backToProject')
      this.parentURL = '/project/' + val.params.projectId
      this.isPrev = false
      this.isOnlyTitle = true
    },
    navOverview(val) {
      this.parentTitle = this.$t('label.projects')
      this.parentURL = '/'
      this.isPrev = true
      this.isOnlyTitle = false
    },
    navDashboard(val) {
      this.parentTitle = this.$t('label.projects')
      this.parentURL = '/'
      this.isPrev = true
      this.isOnlyTitle = false
    },
    setNewsPanelVisible(visible) {
      this.newsPanelVisible = visible
      if (visible) {
        // unreadNewsItems can't be made reactive because it depends on newsPanel internal state.
        // Instead set the field here manually; it will be loaded fine on next page load.
        this.setUnreadNewsItems(false)

        this.$store.dispatch('updateSetting', {
          settingName: 'LastNewsItemRead',
          settings: `${moment.now()}`
        })
      }
    },
    setUnreadNewsItems(value) {
      this.unreadNewsItems = value
    }
  }
}
</script>
<style lang="less">
#indexMenu {
  .glyphicon {
    padding: 0px !important;
  }
}
header {
  position: fixed;
  top: 0;
  color: #fff;
  background: #67ac45;
  width: 100%;
  z-index: 100;
  height: 60px;

  .back-button {
    display: none;

    span {
      float: left;
      line-height: 40px;
      font-size: 24px;
      width: 50px;
      cursor: pointer;
    }
  }

  .title-logo {
    float: left;
    width: 50px;
    margin: 5px 15px 0 15px;
  }

  .title-text {
    float: left;
    margin-top: 7px;

    .title-projects {
      font-size: 26px;
      line-height: 40px;
      margin: 0;
      font-weight: 400;
    }

    a {
      color: #fff;
    }

    .current {
      font-size: 20px;
    }
  }

  .displayMode {
    float: right;
    margin-right: 10%;
    height: 100%;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      height: 100%;
      li {
        display: block;
        float: left;
        height: 100%;
        font-size: 24px;
        margin: 0;
        padding: 0;
        cursor: pointer;
        transition: transform 0.2s ease-in;

        span {
          margin-top: 12px;
        }

        &:hover {
          transform: scale(0.9);
        }
      }
      .active {
        background-color: rgba(255, 255, 255, 0.35);
        cursor: auto;
        &:hover {
          transform: none;
        }
      }
    }
  }

  .dropdown {
    float: right;
    line-height: 40px;
    margin: 10px;

    .dropdown-toggle {
      cursor: pointer;

      span {
        font-size: 24px;
        line-height: 40px;
      }
    }

    .dropdown-menu {
      left: inherit;
      right: 0;
      min-width: 130px;
      font-size: 16px;

      & > li > a {
        padding: 6px 20px;

        &:hover {
          background: #67ac45;
          color: #fff;
        }
      }
    }
  }

  .username {
    float: right;
    font-size: 16px;
    margin-top: 19px;
  }

  .newsmenu {
    float: right;
    margin-top: 8px;
    margin-right: 5px;
    margin-bottom: 4px;
    cursor: pointer;
    padding: 12px;
    border-radius: 6px;
  }

  .newsmenu-icon {
    height: 20px;
  }

  .newsmenu:hover,
  .newsmenu-background-active {
    background-color: @hover-with-background;
  }

  .newsmenu-unread {
    background-color: @rose500;
    width: 0.75em;
    height: 0.75em;
    border-radius: 100%;
    position: absolute;
    margin-left: 14px;
    bottom: 12px;
    border: 2px solid #fff;
  }
}
</style>
