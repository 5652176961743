<template>
  <div class="newspanel ti-column" :class="newsPanelVisible ? 'newspanel-slide-in' : 'newspanel-slide-out'">
    <div class="newspanel-header">
      <font-awesome-icon icon="fa-solid fa-xmark" class="header-item newspanel-close-icon" @click="setNewsPanelVisible(false)"/>
      <p class="newspanel-header-text">{{ $t("label.News") }}</p>
      <a :href="languageWikiLink" class="newspanel-header-link">Visit wiki</a>
    </div>
    <div class="newspanel-content ti-row">
      <div v-if="newsItems && newsItems.length > 0" class="ti-row">
        <NewsItem v-for="newsItem in newsItems" :key="newsItem.id" :newsItemData="newsItem" :language="language" />
      </div>
      <div v-else>
        <div v-if="newsPanelItemsLoading" class="newspanel-loading">
          <span class="glyphicons glyphicons-refresh spinning"> </span>
        </div>
        <div v-else class="newspanel-notfound">
          <p>{{ $t("message.NoNewsItems") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cookie from 'vue-cookie'
import NewsItem from './newsItem'

export default {
  name: 'NewsPanel',
  components: {
    NewsItem
  },
  props: {
    newsPanelVisible: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      language: cookie.get('language'),
      newsPanelItemsLoading: true
    }
  },
  created () {
    this.checkReadNewsItems()
  },
  computed: {
    newsItems () {
      return this.$store.getters.newsItems
    },
    languageLink () {
      switch (this.language) {
        case 'nl':
          return 'https://terraindex.com/nieuws/'
        case 'fr':
          return 'https://terraindex.com/fr/actualites/'
        default:
          return 'https://terraindex.com/en/news/'
      }
    },
    languageWikiLink () {
      switch (this.language) {
        case 'nl':
          return 'https://wiki.terraindex.com/bin/view/Main/?language'
        default:
          return 'https://wiki.terraindex.com/bin/view/Main/?language=en'
      }
    }
  },
  watch: {
    newsItems: {
      immediate: true,
      handler (value) {
        this.newsPanelItemsLoading = false

        if (!value || value.length === 0) return

        if (this.newsPanelVisible) {
          this.updateReadNewsItems()
        }
      }
    }
  },
  methods: {
    setNewsPanelVisible (value) {
      this.newsPanelItemsLoading = true
      this.$emit('update:newsPanelVisible', value)
    },
    async checkReadNewsItems () {
      if (this.newsItems.length === 0) {
        await this.$store.dispatch('loadNewsItems')
      }
      this.$store.dispatch('getSettingByName', {
        settingName: 'LastNewsItemRead'
      }).then((response) => {
        if (!this.newsItems) return
        const dates = this.newsItems.map((newsItem) => new Date(newsItem.date))
        const lastReadNewsItemDate = new Date(parseInt(response.data.SettingValue, 10))
        this.$emit('update:unreadNewsItems', !lastReadNewsItemDate || isNaN(lastReadNewsItemDate) || Math.max(...dates) > lastReadNewsItemDate)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.newspanel {
  position: absolute;
  width: 50vw;

  margin-top: 60px;
  margin-bottom: 60px;
  height: calc(100vh - 120px);

  background-color: @brownTerra100;
  box-shadow: 0px 24px 48px -12px rgba(16, 24, 40, 0.18);

  right: 0vw;
  transition: transform 0.33s;
}

.newspanel-slide-in {
  transform: translateX(0vw);
}

.newspanel-slide-out {
   transform: translateX(~"max(350px, 50vw)");
}

.header-item {
  color: white;
  padding: 5px;
  margin: 5px;
  font-size: 20px;
}

.newspanel-header {
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 13px 32px 13px 21px;
  border-bottom: 1px solid @brownTerra200;
}

.newspanel-close-icon {
  cursor: pointer;
  font-size: 18px;
  padding: 9px 11px;
  border-radius: 6px;
  margin: 0;
  line-height: 22px;
  color: #020202;
}

.newspanel-close-icon:hover {
  background-color: @hover-white-button;
}

.newspanel-header-text{
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  margin: 0 30px 0 8px;
  color: @brownTerra700;
}

.newspanel-header-link{
  padding: 10px 18px;
  text-align: center;
  margin-left: auto;
  height: 44px;
  text-decoration: none;

  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  display: inline-block;
  background-color: @greenTerra600;
  border-radius: 8px;
}

.newspanel-header-link:hover{
  background-color: @greenTerra700;
}

.newspanel-loading {
  display: flex;
  justify-content: center;
  font-size: 100px;
  padding: 100px;
}

.ti-column {
  display: flex;
  flex-direction: column;
}

.newspanel-notfound {
  display: flex;
  font-weight: bold;
  justify-content: center;
  font-size: 20px;
  padding-top: 100px;
}

@keyframes spinning {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.spinning {
  color: #67ac45;
  animation-name: spinning;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.newspanel-content {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

</style>
