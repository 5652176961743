<template>
  <div class="newsitem">
    <div class="newsitem-body">
      <div class="newsitem-content" v-html="newsItemData.content.rendered"></div>
      <div class="newsitem-footer">
        <a class="newsitem-link" :href="newsItemLink" target="_blank" rel="noopener noreferrer">
          {{ $t('label.ReadMore') }}
        </a>
        <span id="newsitem-date">{{ date }}</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'NewsItem',
  props: {
    newsItemData: {
      type: Object,
      required: true
    },
    language: {
      type: String,
      required: true
    }
  },
  computed: {
    newsItemLink() {
      const customLink = this.newsItemData.yoast_head_json.description
      if (customLink && customLink.includes('https:/')) {
        return customLink.replaceAll('$25', '%20')
      }

      return this.newsItemData.link
    },
    date() {
      return new Date(this.newsItemData.date).toLocaleDateString()
    }
  }
}
</script>

<style lang="less" scoped>
#newsitem-date {
  color: #6C737F
}
.newsitem-footer {
  display: flex;
  justify-content: space-between;
  align-items: center
}

.newsitem {
  margin: 25px;
}

:deep(img) {
  max-width: 100%;
  height: auto;
}

.newsitem-title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin: 0 9px 10px 9px;
  color: @gray500;
}

.newsitem-body {
  color: black;
  padding: 0px 24px 24px 24px;
  display: inline-block;
  min-height: 150px;
  background-color: white;
  border: 1px solid @brownTerra200;
  border-radius: 4px;
}

.newsitem-content {
  line-height: 20px;
  font-size: 14px;
  margin-bottom: 24px;
}

/* ::v-deep style is for the v-html styling */
.newsitem-content::v-deep p {
  font-weight: 400;
}

.newsitem-content::v-deep h4,
.newsitem-content::v-deep strong {
  font-weight: 700;
  line-height: 20px;
  font-size: 14px;
}

.newsitem-content a::v-deep,
.newsitem-content::v-deep p a {
  color: @greenTerra700;
  font-weight: 600;
  text-decoration: none;
}

.newsitem-link {
  font-weight: bold;
  border-radius: 3px;
  padding: 8px 14px;
  background: #FFFFFF;
  color: @gray700;
  text-decoration: none;
  border: 1px solid @gray300;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
  text-align: center;
  display: inline-block;
}

.newsitem-link:hover {
  background-color: @gray100;
}</style>
